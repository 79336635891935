// Variables
export const Length = 0;
export const Width = 1;
export const Height = 2;
export const Material = 3;
export const Density = 4;
export const Volume = 5;
export const Surface_Area = 6;
export const VolToSurfArea = 7;
export const Girth = 8;
export const Length_Girth = 9;
export const Diagonal = 10;
export const Weight = 11;

// Labels (Properties)
export const COMMENT = 0;