export function getCatalogNames() {
    var result = [];
//    console.log('In getCatalogNames result=',result);
    return result;
}

export function getCatalogEntries(name, st, viol_wt) {
//    console.log('In getCatalogEntries name=',name,' st=',st,' viol_wt=',viol_wt);
    var result = [];
//    console.log('In getCatalogEntries result=',result);
    return result;
}
