export function getSizeTypes() {
    var result = [];
//    console.log('In getSizeTypes result=',result);
    return result;
}

export function getSizeEntries(type, st) {
//    console.log('In getSizeEntries type=',type,' st=',st);
    var result = [];
//    console.log('In getSizeEntries result=',result);
    return result;
}
