// Variables
export const OD_Free = 0;
export const Wire_Dia = 1;
export const Coils_T = 2;
export const M_1 = 3;
export const M_2 = 4;
export const Coil_Spacing = 5;
export const Mean_Dia = 6;
export const ID_Free = 7;
export const Coils_A = 8;
export const Rate = 9;
export const Deflect_1 = 10;
export const Deflect_2 = 11;
export const L_Body = 12;
export const L_1 = 13;
export const L_2 = 14;
export const End_Angle_Free = 15;
export const Stroke = 16;
export const Weight = 17;
export const Spring_Index = 18;
export const End_Deflect_All = 19;
export const Stress_1 = 20;
export const Stress_2 = 21;
export const Stress_End = 22;
export const FS_2 = 23;
export const FS_CycleLife = 24;
export const Cycle_Life = 25;
export const PC_Safe_Deflect = 26;
export const Force_Arm_2 = 27;
export const Energy = 28;
export const Spring_Type = 29;
export const Prop_Calc_Method = 30;
export const Material_Type = 31;
export const ASTM_Fed_Spec = 32;
export const Process = 33;
export const Heat_Treat = 34;
export const Catalog_Number = 35;
export const Material_File = 36;
export const Life_Category = 37;
export const Inactive_Coils = 38;
export const Density = 39;
export const Elastic_Modulus = 40;
export const Hot_Factor_Kh = 41;
export const Tensile = 42;
export const PC_Ten_Bnd_Endur = 43;
export const PC_Ten_Bnd_Stat = 44;
export const Stress_Lim_Bnd_Endur = 45;
export const Stress_Lim_Bnd_Stat = 46;
export const End_Type = 47;
export const Arm_1 = 48;
export const Arm_2 = 49;
export const Xlen_1 = 50;
export const Xlen_2 = 51;
export const L_End_1 = 52;
export const L_End_2 = 53;
export const tbase010 = 54;
export const tbase400 = 55;
export const const_term = 56;
export const slope_term = 57;
export const tensile_010 = 58;

// Labels (Properties)
export const COMMENT = 0;
export const Contact_person = 1;
export const Company_name = 2;
export const Street = 3;
export const City__State___Zip = 4;
export const Phone___email = 5;
export const Date = 6;
export const Part_Number = 7;
export const Data_Source = 8;
export const Mandril = 9;
export const Wind = 10;
export const Relative_end_pos____tol_ = 11;
export const Shot_peen = 12;
export const Stress_relieve_HT = 13;
export const Finish = 14;
export const End_use = 15;
export const Operating_temp = 16;
export const Special_notes___tol = 17;
export const Customer_approval = 18;
export const Customer_date = 19;
export const Vendor_approval = 20;
export const Vendor_date = 21;