import { CONSTRAINED } from '../../store/actionTypes';
export const initialState = {
    "symbol_table": [
        {
            "input": true,
            "name": "PRESSURE",
            "value": 3.4,
            "units": "MPa",
            "lmin": 0,
            "lmax": CONSTRAINED,
            "cmin": 0,
            "cmax": 10.3,
            "validmin": -Number.MAX_VALUE,
            "validmax": Number.MAX_VALUE,
            "sdlim": 1.0,
            "tooltip": "Gas PRESSURE applied to piston AREA",
            "type": "equationset",
            "hidden": false
        },
        {
            "input": true,
            "name": "RADIUS",
            "value": 10.2,
            "units": "mm",
            "lmin": CONSTRAINED,
            "lmax": CONSTRAINED,
            "cmin": 1.0,
            "cmax": 12.7,
            "validmin": Number.MIN_VALUE,
            "validmax": Number.MAX_VALUE,
            "sdlim": 1,
            "tooltip": "Piston dimension; creates AREA where PRESSURE is applied",
            "type": "equationset",
            "hidden": false
        },
        {
            "input": true,
            "name": "THICKNESS",
            "value": 1.02,
            "units": "mm",
            "lmin": CONSTRAINED,
            "lmax": CONSTRAINED,
            "cmin": 0.1,
            "cmax": 1.27,
            "validmin": Number.MIN_VALUE,
            "validmax": Number.MAX_VALUE,
            "sdlim": 0.1,
            "tooltip": "Dimension of cylinder wall providing containment for PRESSURE",
            "type": "equationset",
            "hidden": false
        },
        {
            "input": false,
            "name": "FORCE",
            "value": 0,
            "units": "newtons",
            "lmin": CONSTRAINED,
            "lmax": 0,
            "cmin": 4400,
            "cmax": 44500,
            "validmin": -Number.MAX_VALUE,
            "validmax": Number.MAX_VALUE,
            "sdlim": 1000,
            "tooltip": "Push generated by PRESSURE acting on piston AREA",
            "type": "equationset",
            "hidden": false
        },
        {
            "input": false,
            "name": "AREA",
            "value": 0,
            "units": "sq-mm",
            "lmin": 0,
            "lmax": 0,
            "cmin": 0,
            "cmax": 6450,
            "validmin": 0.0,
            "validmax": Number.MAX_VALUE,
            "sdlim": 1000,
            "tooltip": "Effective amount of piston surface exposed to PRESSURE",
            "type": "equationset",
            "hidden": false
        },
        {
            "input": false,
            "name": "STRESS",
            "value": 0,
            "units": "MPa",
            "lmin": 0,
            "lmax": CONSTRAINED,
            "cmin": 0,
            "cmax": 21,
            "validmin": -Number.MAX_VALUE,
            "validmax": Number.MAX_VALUE,
            "sdlim": 5,
            "tooltip": "Force per unit area within cylinder wall; created by PRESSURE",
            "type": "equationset",
            "hidden": false
        }
    ],
    "labels": [
        {
            "name": "COMMENT",
            "value": "PCYL default start point - Metric units ..."
        }
    ],
    "type": "Piston-Cylinder",
    "version": "7",
    "result": {
        "objective_value": 0,
        "termination_condition": ""
    },
    "jsontype": "ODOP",
    "units": "Metric"
};
