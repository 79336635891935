export const matnam = 0;
export const astm_fs = 1;
export const fedspec = 2;
export const dens = 3;
export const ee = 4;
export const gg = 5;
export const kh = 6
export const t010 = 7;
export const t400 = 8;
export const pte1 = 9;
export const pte2 = 10;
export const pte3 = 11;
export const pte4 = 12;
//pte5 
export const pte6 = 13;
export const pte7 = 14;
export const pte8 = 15;
export const ptb1 = 16;
export const ptb2 = 17;
export const ptb3 = 18;
export const ptb4 = 19;
//ptb5
export const ptb6 = 20;
export const ptb7 = 21;
export const ptb8 = 22;
export const silf = 23;
export const sihf = 24;
export const sisr = 25;
export const wire_dia_filename = 26;
export const od_free_filename = 27;
export const dumyc = 28;
export const longnam = 29;
